body {
  background-color: #ffd0e0 !important;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  color: #333;
}

.Appointment {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 2%;
  max-width: 600px;
  margin: 5% auto;
  background-color: #ffd0e0 !important; /* Light theme color */
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(201, 55, 101, 0.5); /* Shadow with darker theme color */
  border: 2px solid #c93765; /* Border with primary theme color */
}

.inputfiled {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.inputfiled label {
  margin-bottom: 5px;
  font-weight: bold;
}

.inputfiled input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.btn-Class {
  padding: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
button {
  /* margin-right: 20px; */
  padding: 10px 20px;
  background-color: #c93765;
  color: white;
  border: none;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

button:hover {
  background-color: #ffff;
  color: black;
  border-radius: 25px;
}

.MuiDatePicker-root,
.MuiTimeClock-root {
  width: 100%;
}

.MuiTimeClock-root {
  margin-top: 20px;
  display: none; /* Initially hidden */
}

.Appointment form {
  width: 100%;
}

/* Show TimeClock when input is focused or clicked */
input:focus + .MuiTimeClock-root,
input:focus-within + .MuiTimeClock-root {
  display: block;
}

/* SVG background in the top left corner */
/* .background-svg {
    background-image: url("../public/images/Group6.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 265px;
    height: 207px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1; To place it behind other content */
/* } */

nav {
  position: fixed;
  top: 0;
  right: 0;
  margin: 20px;
  z-index: 1000;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 15px;
}

nav ul li {
  font-size: 18px;
  font-weight: bold;
  color: #c93765;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

nav ul li:hover {
  background-color: #ffd0e0 !important;
  color: #282828;
}

.home {
  color: #ffd0e0 !important;
  background-color: #c93765;
  border-radius: 5px;
  padding: 10px 15px;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.table th,
.table td {
  border: 2px solid #c93765;
  padding: 10px;
  text-align: left;
}

.table th {
  background-color: #c93765;
  color: white;
}

.table td {
  border-color: #ffd0e0 !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.text-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mt-5 {
  margin-top: 3rem;
}

#green {
  color: green;
}

#btn {
  border: 2px solid #c93765; /* Border with primary theme color */
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px 20px;
  text-shadow: 2px 2px 4px rgba(156, 77, 77, 0.8),
    0 0 10px rgba(255, 208, 224, 0.5),
    /* Glow effect with light theme color */ 0 0 20px rgba(255, 208, 224, 0.3);
  background-color: #c93765; /* Background with primary theme color */
  color: #ffd0e0 !important; /* Text color matching the light theme color */
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

#btn:hover {
  box-shadow: 0 0 10px rgba(255, 208, 224, 0.8),
    0 0 20px rgba(255, 208, 224, 0.6), 0 0 30px rgba(255, 208, 224, 0.4);
  color: #c93765; /* Text color changes to the primary theme color on hover */
  background-color: #ffd0e0 !important; /* Background color changes to the light theme color on hover */
  border-color: #ffd0e0; /* Border color also changes on hover */
}

a {
  text-decoration: none;
  color: #c93765;
}
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #c93765; /* Use the same color as your border */
  padding: 10px;
  text-align: left;
}

th {
  background-color: #c93765;
  color: white;
}
/* 
border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25); */
.changeBorder {
  border-color: #c93765 !important;
  outline: 10;
  /* box-shadow: 0 0 0 0.25rem #c93765 !important; */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .Appointment {
    padding: 5% 5%; /* More padding for smaller screens */
  }

  nav ul {
    flex-direction: column; /* Stack navigation links */
    align-items: flex-start; /* Align links to the left */
  }

  nav ul li {
    font-size: 16px; /* Smaller font size */
    padding: 8px 10px; /* Reduced padding */
  }

  .inputfiled input {
    font-size: 14px; /* Smaller input text */
  }

  button {
    font-size: 14px; /* Smaller button text */
    padding: 8px 15px; /* Reduced padding */
  }

  /* Responsive Table Styles */
  table {
    display: flex; /* Make the table block for responsive layout */
    overflow-x: auto; /* Allow horizontal scrolling */
    white-space: nowrap; /* Prevent text wrapping */
    justify-content: center;
  }

  thead {
    display: none; /* Hide the header on small screens */
  }

  tr {
    display: block; /* Stack rows */
    margin-bottom: 10px; /* Space between rows */
  }

  td {
    display: flex; /* Make cells flexible */
    justify-content: space-between; /* Space between label and value */

    padding: 10px;
    border: none; /* Remove border for a cleaner look */
    background: #fff; /* Set a white background for cells */
    position: relative; /* Position relative for pseudo elements */
  }

  td::before {
    content: attr(data-label); /* Display the header label for each cell */
    position: absolute;
    left: 10px; /* Adjust position */
    top: 10px; /* Adjust position */
    font-weight: bold; /* Bold for emphasis */
    color: #c93765; /* Color to match your theme */
  }
}

@media (max-width: 480px) {
  .Appointment {
    max-width: 90%; /* Allow more width on small screens */
    margin: 10% auto; /* Adjust margin */
  }

  nav ul {
    margin: 10px 0; /* Adjust margin */
  }

  .inputfiled input {
    font-size: 12px; /* Smaller input text */
  }

  button {
    font-size: 12px; /* Smaller button text */
    padding: 6px 12px; /* Reduced padding */
  }

  .table th,
  .table td {
    font-size: 14px; /* Smaller table text */
  }
}
